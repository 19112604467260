<template>
  <div class="maincon">
    <div class="bmap-page-container">
      <el-bmap vid="bmapDemo" :bmap-manager="bmapManager" :zoom="zoom" :center="center" :events="events"
        class="bmap-demo">
        
       <weixing-plugin :map="map"></weixing-plugin>
        <el-bmap-polygon :v-if="polygons.length" v-for="(polygon, index) in polygons" :visible="visible" :key="polygon.path"
          :vid="polygon.path" :ref="`polygon_${polygon.path}`" :path="polygon.path"  :fillColor="polygon.fillColor" strokeStyle="dashed"  :enable-editing="polygon.enableEditing"
          :events="polygon.events"></el-bmap-polygon>


      </el-bmap>
    </div>

  </div>
</template>

<script>

import weixingPlugin from '@/components/bmap/weixingPlugin.vue'
import VueBMap from "vue-bmap-gl";
let bmapManager = new VueBMap.BMapManager();

export default {

  props: {
    cname: String,
    polygondata: {
      type: Array,
      default: function () {
        return []
      }
    }
  },

  components:{
    weixingPlugin
  },
  data() {
    return {
      area: '',
      blockoverlay:{} //板块覆盖物
    }
  },
  watch: {
    cname(v) {
      // console.log('===',this.polygondata)
      // let myGeo = new BMapGL.Geocoder();
      // var that = this
      // myGeo.getPoint(v + '人民政府', function (point) {
      //   if (point) {
      //     that.map.centerAndZoom(point)
      //   } else {
      //     alert('您选择的地址没有解析到结果！');
      //   }
      // }, v + '市')
      // this.setAreaLine(v)
    },

    polygondata: {

      handler(v) {
        // alert(1)
        // console.log('==data==', v)
        // this.handlerData(v)
        v.map(item => {
          this.handlerData(item.set)
        })
      },
      deep: true
    }


  },

  data() {
    return {
      visible: true,
      polygons: [],
      map: {},
      bmapManager,
      geodata: "",
      events: {
        init: (o) => {
          this.map = o
          let currentname = this.cname
          let myGeo = new BMapGL.Geocoder();
          // 将地址解析结果显示在地图上，并调整地图视野
          // let that = this;
          // myGeo.getPoint(currentname + '市人民政府', function (point) {
          //   if (point) {
          //     o.centerAndZoom(point, 10);
          //     // o.addOverlay(new BMapGL.Marker(point, {title: ' '}))
          //     that.setAreaLine(currentname)
          //   } else {
          //     alert('您选择的地址没有解析到结果！');
          //   }
          // }, currentname + '市')

          // 将地址解析结果显示在地图上，并调整地图视野

        },
      },
      count: 1,
      center: [0, 0],
      zoom: 12
    };
  },
  methods: {

    // 处理数据
    handlerData(data) {
      const dataArr = JSON.parse(data) // 获取地图数据数组
      dataArr.map(obj => {
      
        this.drawPloygonByBD(obj)
      })
    },

    // 获取手动画 数据库api数据 画多变形
    drawPloygonByBD(obj) {
      // this.map.clearOverlays(); //清除地图覆盖物
      this.map.removeOverlay(this.blockoverlay) // 添加覆盖物
      var ply = new BMapGL.Polygon(obj.multipoint, {
        strokeWeight: 1,

        strokeStyle:'dashed',
        fillColor:'#dedede',
        strokeColor:'#000000'
      }) // 建立多边形覆盖物
      // console.log('===ply==', ply)
      // this.overlaycompleteByBD(ply)
      this.blockoverlay = ply
      this.map.addOverlay( this.blockoverlay ) // 添加覆盖物
      this.map.setViewport(ply.getPath()) // 调整视野
    },



    toChangeLocation() {

    },

    changeCenter() {
      let o = this.bmapManager.getMap()
      console.log(o)
    },
    setAreaLine(cname) {
      if (cname) {
        // that.polygons = []

        var bdary = new BMapGL.Boundary();
        var that = this;
        bdary.get(cname, function (rs) {
          //这里是用户自己的函数。 
          console.log(rs)
          const newbounderies = rs.boundaries.map(item => {
            let single = item.split(';');
            let newItem = single.map(v => {
              return v.split(',')
            })
            const object = {
              path: newItem
            }

            return object;
          })

          that.polygons = newbounderies;
        });
      }

    }
  },
  // watch:{
  //   // cname(cname){
  //   //   console.log(cname)

  //   //   console.log(this.$store.state.currentCity)

  //   //   if(this.$store.state.currentCity.label == cname){
  //   //     var lat = this.$store.state.currentCity.lat;

  //   //     console.log(this.$store.state.currentCity)
  //   //     var lng = this.$store.state.currentCity.lng;
  //   //       var zoom = this.$store.state.currentCity.zoom;
  //   //   }else{
  //   //     var cu = this.$store.state.currentCity.filter(res=>{
  //   //       return res.label == cname;
  //   //     })
  //   //     console.log(cu)
  //   //      var lat = cu[0].lat;
  //   //      var lng = cu[0].lng;
  //   //      var zoom =  cu[0].zoom;

  //   //   }


  //   //   this.center = [lng,lat]
  //   //   this.zoom = zoom

  //   //   this.setAreaLine(cname)

  //   // }
  // },

};
</script> 
<style lang="scss">
.maincon {
  position: relative;
  height: calc(100vh - 120px);
}

.contrl {
  position: absolute;
  ;
  right: 0;
  top: 0;
  width: 200px;
  background: #fff;
  height: calc(100vh - 120px);
  z-index: 9999
}

.bmap-demo {
  height: calc(100vh - 140px);
}
</style>